import { Carousel } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@uidotdev/usehooks";
import { ProjectInfo } from "../components/Projects";
import ProjectDetail from "../components/ProjectDetail";

interface ProjectProps {
    projectInfo: ProjectInfo;
}

const Project = ({ projectInfo }: ProjectProps) => {
    const [hoveredSection, setHoveredSection] = useState<string | undefined>(
        undefined,
    );

    const navigate = useNavigate();
    const desktop = useMediaQuery("only screen and (min-width : 993px)");

    useEffect(() => {
        document.title = `ml | ${projectInfo.title}`;
    }, []);

    useEffect(() => {
        if (!desktop) {
            navigate("/");
        }
    }, [desktop]);

    return (
        <div className="flex">
            <div className="p-[2em] w-[45%] h-[100vh] border-black flex flex-col justify-between sticky top-0">
                {/* <svg
                    onClick={() => navigate("/")}
                    className=" h-6 w-6 cursor-pointer"
                    viewBox="0 0 20 20"
                >
                    <path d="M232,200a8,8,0,0,1-16,0,88.1,88.1,0,0,0-88-88H51.31l34.35,34.34a8,8,0,0,1-11.32,11.32l-48-48a8,8,0,0,1,0-11.32l48-48A8,8,0,0,1,85.66,61.66L51.31,96H128A104.11,104.11,0,0,1,232,200Z" />
                </svg> */}
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => navigate("/")}
                    className="h-6 w-6 hover:cursor-pointer hover:h-8 hover:w-8"
                    width="32"
                    height="32"
                    fill="#ffffff"
                    viewBox="0 0 256 256"
                >
                    <path d="M232,200a8,8,0,0,1-16,0,88.1,88.1,0,0,0-88-88H51.31l34.35,34.34a8,8,0,0,1-11.32,11.32l-48-48a8,8,0,0,1,0-11.32l48-48A8,8,0,0,1,85.66,61.66L51.31,96H128A104.11,104.11,0,0,1,232,200Z"></path>
                </svg>

                <div className="">
                    <p className="w-fit mb-[8px]">
                        <a href={projectInfo.link}> {projectInfo.title}</a>
                        <br></br>
                        <ProjectDetail
                            name="date"
                            values={[projectInfo.madeDate]}
                            hoveredSection={hoveredSection}
                            setHoveredSection={setHoveredSection}
                        />
                    </p>
                    <div className="text-gray-50">
                        <p className="w-full h-fit italic text-base ">
                            {projectInfo.description}
                        </p>
                        <div className="h-fit mt-[2em]">
                            {projectInfo.link && (
                                <ProjectDetail
                                    name="link"
                                    values={[projectInfo.link]}
                                    hoveredSection={hoveredSection}
                                    setHoveredSection={setHoveredSection}
                                />
                            )}

                            <ProjectDetail
                                name="tools"
                                values={projectInfo.tools}
                                hoveredSection={hoveredSection}
                                setHoveredSection={setHoveredSection}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className=" w-[90%] flex flex-wrap gap-2 m-[1em]">
                {projectInfo.imageURLs?.map((imgPath, index) => (
                    <img
                        key={index}
                        src={`images/${imgPath}`}
                        // className={
                        //     projectInfo.type === "web"
                        //         ? "p-4 h-full w-full object-contain"
                        //         : "h-full w-full object-cover"
                        // }
                        alt={"image" + (index + 1)}
                    />
                ))}
            </div>
            {/* {projectInfo.imageURLs && (
                <Carousel
                    placeholder={""}
                    className={`rounded h-[70vh] w-fit`}
                    loop={true}
                >
                    {projectInfo.imageURLs.map((imgPath, index) => (
                        <img
                            key={index}
                            src={`images/${imgPath}`}
                            className={
                                projectInfo.type === "web"
                                    ? "p-4 h-full w-full object-contain"
                                    : "h-full w-full object-cover"
                            }
                            alt={"image" + (index + 1)}
                        />
                    ))}
                </Carousel>
            )} */}
        </div>
    );
};

export default Project;
