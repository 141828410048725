import ProjectThumbnail, { ProjectThumbnailMobile } from "./ProjectThumbnail";
import { useSelector } from "react-redux";
import {
    selectAllProjects,
    selectWebProjects,
    selectGameProjects,
} from "../redux/projects/projectsSlice";

export interface ProjectInfo {
    title: string;
    path: string;
    madeFor: string;
    madeDate: string;
    tools: string[];
    description: string;
    type: "game" | "web";
    gifURL: string;
    link?: string;
    imageURLs?: string[];
}

interface ProjectProps {
    filter?: string;
}

const Projects = ({ filter }: ProjectProps) => {
    const projects = useSelector(
        !filter
            ? selectAllProjects
            : filter === "web"
              ? selectWebProjects
              : selectGameProjects,
    );

    const desktopLayout = "block pb-[1em]";

    return (
        <div className={desktopLayout}>
            <p className="mb-[2em] "> Selected Work</p>
            {projects.map((project, index) => (
                <ProjectThumbnail key={index} projectInfo={project} />
            ))}
        </div>
    );
};

export const ProjectsMobile = () => {
    const projects = useSelector(selectAllProjects);

    const mobileLayout = "block mx-auto w-fit";

    const desktopLayout = "block pb-[1em]";

    return (
        <div className={desktopLayout}>
            <p className="mb-[2em] "> Selected Work</p>
            {projects.map((project, index) => (
                <ProjectThumbnailMobile key={index} projectInfo={project} />
            ))}
        </div>
    );
};

export default Projects;
