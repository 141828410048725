import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ProjectImage from "./ProjectImage";
import {
    Carousel,
    Accordion,
    AccordionBody,
    AccordionHeader,
} from "@material-tailwind/react";
import { ProjectInfo } from "./Projects";

interface ProjectThumbnailProps {
    projectInfo: ProjectInfo;
}

const minSwipeDistance = 50;

const ProjectThumbnail = ({ projectInfo }: ProjectThumbnailProps) => {
    const navigate = useNavigate();

    const onProjectClicked = () => {
        navigate(projectInfo.path);
    };

    const desktopTitleStyle = "mt-[1em] mb-[3em] text-white ";

    return (
        <a
            className="cursor-pointer mb-[8em]  rounded-md drop-shadow-sm  "
            href={projectInfo.link}
        >
            <ProjectImage
                filename={`gifs/${projectInfo.gifURL}`}
                className="hover:-translate-y-2 transition duration-400 ease-in-out w-full rounded-lg border-solid border-gray-100 	"
                alt="uhh"
            />
            <div className={desktopTitleStyle}>
                {projectInfo.title} &nbsp;
                <div className="flex flex-wrap text-sm opacity-50">
                    {projectInfo.tools.map((tool, index) => (
                        <p key={index} className={``}>
                            {tool}
                            <span className="px-[2px]">
                                {index < projectInfo.tools.length - 1 && "·"}
                            </span>
                        </p>
                    ))}
                </div>
            </div>

            {
                // desktop && <p className="mb-2">{projectInfo.description}</p>
            }
        </a>
    );
};

interface ProjectThumbnailMobileProps {
    projectInfo: ProjectInfo;
}

export const ProjectThumbnailMobile = ({
    projectInfo,
}: ProjectThumbnailMobileProps) => {
    const mobileTitleStyle =
        "mb-1  px-[1em] pt-[1em] pb-[1em] bg-white bg-opacity-10 block";
    const descOpenStyle = "border-none text-md py-0 text-black";
    const descClosedStyle = "underline border-none text-md py-0 text-black";

    const navigate = useNavigate();

    const onProjectClicked = () => {
        navigate(projectInfo.path);
    };
    return (
        <div
            className="cursor-pointer mb-[2em]  rounded-md hover:-translate-y-2 drop-shadow-sm  transition duration-400 ease-in-out"
            onClick={() => onProjectClicked()}
        >
            <ProjectImage
                filename={`gifs/${projectInfo.gifURL}`}
                className="bg-white bg-opacity-10 border-gray-100 px-[1em] pt-[1em]"
                alt="uhh"
            />
            <div className={mobileTitleStyle}>
                {projectInfo.title} &nbsp;
                <div className="flex flex-wrap opacity-50">
                    {projectInfo.tools.map((tool, index) => (
                        <p key={index} className="text-sm ">
                            {tool}
                            <span className="px-[2px]">
                                {index < projectInfo.tools.length - 1 && "·"}
                            </span>
                        </p>
                    ))}
                </div>
            </div>

            {
                // desktop && <p className="mb-2">{projectInfo.description}</p>
            }
        </div>
    );
};

export default ProjectThumbnail;
