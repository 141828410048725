interface ProjectImageProps {
    filename: string;
    alt: string;
    className: string;
}

const ProjectImage = ({ filename, alt, className }: ProjectImageProps) => {
    return (
        <div className="drop-shadow-lg">
            <img
                className={className}
                src={`${process.env.PUBLIC_URL}/${filename}`}
                alt={alt}
                loading="lazy"
            />
        </div>
    );
};

export default ProjectImage;
