import { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";

interface ProjectDetailProps {
    name: string;
    values: string[];
    hoveredSection: string | undefined;
    setHoveredSection: Dispatch<SetStateAction<string | undefined>>;
    small?: boolean;
}
const ProjectDetail = ({
    name,
    values,
    hoveredSection,
    setHoveredSection,
    small,
}: ProjectDetailProps) => {
    return (
        <div
            className={`flex justify-between ${hoveredSection && hoveredSection === name ? "text-gray-800" : "text-gray-500"}`}
            onMouseOver={() => setHoveredSection(name)}
            onMouseLeave={() => setHoveredSection(undefined)}
        >
            <div className="flex w-full grow-0 shrink  flex-wrap">
                {name === "link" ? (
                    <a href={values[0]} target="_blank" className="w-full">
                        {values[0]} <span className="text-sm">↗</span>
                    </a>
                ) : (
                    values.map((val, index) => (
                        <p
                            key={index}
                            className={`${small && "text-sm"} ${index !== 0 ? "mr-1" : ""} text-right`}
                        >
                            {val}
                            {index !== 0 && ","}
                        </p>
                    ))
                )}
            </div>
        </div>
    );
};

export default ProjectDetail;
