import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ProjectInfo } from "../../components/Projects";

export const projectsSlice = createSlice({
    name: "projects",
    initialState: {
        projects: [] as ProjectInfo[],
    },
    reducers: {
        setProjects: (state, action: PayloadAction<ProjectInfo[]>) => {
            state.projects = action.payload;
        },
        addProject: (state, action: PayloadAction<ProjectInfo>) => {
            state.projects.push(action.payload);
        },
        addProjects: (state, action: PayloadAction<ProjectInfo[]>) => {
            action.payload.forEach((project) => {
                state.projects.push(project);
            });
        },
    },
    selectors: {
        selectAllProjects: (sliceState) => sliceState.projects,
        selectWebProjects: (sliceState) =>
            sliceState.projects.filter((project) => project.type === "web"),
        selectGameProjects: (sliceState) =>
            sliceState.projects.filter((project) => project.type === "game"),
    },
});

export const { setProjects, addProject, addProjects } = projectsSlice.actions;

export const { selectAllProjects, selectWebProjects, selectGameProjects } =
    projectsSlice.selectors;

export default projectsSlice.reducer;
